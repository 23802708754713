<template>
  <div>
    <header-top-dashboard
      primary
      :custom-right-content="true"
      :show-back-button="true"
    >
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-padding">
              <v-form>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Create Product Sub-Category</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Name</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Name"
                      v-model="name"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.name.$errors)
                      "
                      @blur="v$.name.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Parent Category</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Product Category"
                      :items="productCategories"
                      item-text="name"
                      item-value="id"
                      v-model="productCategory"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.productCategory.$errors)
                      "
                      @blur="v$.productCategory.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Status</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Product Category Status"
                      :items="productCategoryStatuses"
                      item-text="value"
                      item-value="key"
                      v-model="status"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.status.$errors)
                      "
                      @blur="v$.status.$touch"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn
                      @click="submit"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      :loading="submitButton.isLoading"
                      class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                      color="#5e72e4"
                      >Submit</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "settings-create",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    HeaderTopDashboard,
  },
  validations() {
    return {
      name: { required },
      productCategory: { required },
      status: { required },
    };
  },
  props: {},
  data() {
    return {
      name: "",
      status: "active",
      productCategory: null,
      productCategories: [],
      submitButton: {
        isLoading: false,
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    getFieldValidationErrors(errors) {
      const messages = [];

      for (let i = 0; i < errors.length; i++) {
        messages.push(`${errors[i].$message}`);
      }

      return messages;
    },

    async submit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.submitButton.isLoading = true;

      const productCategoryObject = {
        name: this.name,
        parent_category_id: this.productCategory,
        status: this.status,
      };

      this.$http
        .post(
          "/product-categories",
          productCategoryObject,
          this.sessionStore.getHttpConfig
        )
        .then(() => {
          this.$notify.success("Product category successfully created!");
          this.$router.push("/product-sub-categories");
        })
        .catch((error) => {
          this.$notify.error(
            "An error occurred while creating the Product category. Please try again later or contact support."
          );
          console.error("Error while creating the Product category: ", error);
        })
        .finally(() => {
          this.submitButton.isLoading = false;
        });
    },

    showFileSelector() {
      const fileInputElement = this.$refs.fileUploadInput;
      fileInputElement.click();
    },
  },
  mounted() {
    this.$http
      .get(`/product-categories?size=200`, this.sessionStore.getHttpConfig)
      .then((response) => {
        this.productCategories.splice(0);
        for (let i = 0; i < response.data.data.length; i += 1) {
          this.productCategories.push(response.data.data[i]);
        }
      })
      .catch((error) => {
        this.handleHttpError(
          error,
          "An error occurred while fetching product categories."
        );
      });
  },
};
</script>
